import axios from "axios";
import { aptosClient, payKey, paymasterBaseUrl } from "../Common/Constants";
const initAccount = async (address: string) => {
  try {
    await aptosClient.getAccount(address);
  } catch (e: any) {
    console.log("catched error in getAccount", e);
    try {
      const url = `${paymasterBaseUrl}/init-account`;
      const data = {
        address,
      };
      const res = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
          "pay-key": payKey,
        },
      });
      console.log("response for Init account", await res.data);
    } catch (e: any) {
      console.log("catched error in paymaster init account ", e);
    }
  }
};
export default initAccount;
