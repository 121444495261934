import Mustang from "../Assests/images/mustang.jpg";
import heart from "../Assests/images/heart.png";
import { getUiAddress } from "../Common/Utils";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";

const NewsFeed = ({
  cast,
  wave,
  isWalletConnected,
}: {
  cast: any;
  wave: any;
  isWalletConnected: boolean;
}) => {
  const [waveCount, setWaveCount] = useState<number>(cast.wave_count);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setWaveCount(cast.wave_count);
    setIsLoading(false);
  }, [cast]);
  const handleWave = async () => {
    if (isWalletConnected) {
      // setWaveCount(Number(cast.wave_count) + 1);
      setIsLoading(true);
      await wave(cast.cast_id - 1);
      return;
    }
    toast.error(`Please login to Like`, {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
  };
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className=" border-b-2 border-[#2f3336] my-8">
        <div className=" flex px-10 ">
          <img
            src={Mustang}
            alt=""
            className=" rounded-full w-[3.5rem] h-[3.5rem]"
          />
          <div className=" text-[1.25rem] text-white font-[700] mx-6 my-3">
            {getUiAddress(cast.caster)}
          </div>
        </div>
        <div className=" flex justify-between">
          <div className=" w-[100%] bg-[#151515] rounded-[0.1rem] flex my-8 mx-6 overflow-auto scrollbar-hide font-[500]">
            <div className="text-white font-[200] text-[1.5rem] p-4 w-full">
              <p className={`truncate ${isExpanded ? "hidden" : "block"}`}>
                {cast.message}
              </p>
              <p
                className={`overflow-hidden ${isExpanded ? "block" : "hidden"}`}
              >
                {cast.message}
              </p>
              <button
                onClick={toggleReadMore}
                className="text-blue-500 pt-2 font-[500] text-[1rem] hover:underline	"
              >
                {cast.message.length > 100
                  ? isExpanded
                    ? "Read Less"
                    : "Read More...."
                  : ""}
              </button>
            </div>
          </div>
          <div className=" flex flex-col py-12 pr-10">
            {isLoading ? (
              <div
                className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
            ) : (
              <>
                <img
                  src={heart}
                  onClick={handleWave}
                  alt="heart"
                  className=" w-6 h-6 cursor-pointer"
                />
                <div>
                  <p className=" px-2 py-2">{waveCount}</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className=" flex justify-between p-[2%_12%]"></div>
      </div>
    </div>
  );
};

export default NewsFeed;
