import Sidebar from "./Sidebar";
import Mustang from "../Assests/images/mustang.jpg";
import NewsFeed from "./NewsFeed";
import { Web3AuthNoModal } from "@web3auth/no-modal";
import {
  CHAIN_NAMESPACES,
  WALLET_ADAPTERS,
  ADAPTER_EVENTS,
} from "@web3auth/base";
import { CommonPrivateKeyProvider } from "@web3auth/base-provider";

import { OpenloginAdapter } from "@web3auth/openlogin-adapter";
import { useEffect, useState } from "react";
import { AptosAccount } from "aptos";
import { getUiAddress } from "../Common/Utils";
import { getAllCasts } from "../Services/GetAllCasts";
import execute from "../Services/Execute";
import IsWhitelisted from "../Services/IsWhitelisted";
import AddToWhitelist from "../Services/AddToWhitelist";
import initAccount from "../Services/InitAccount";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { web3AuthClientId } from "../Common/Constants";

const clientId = web3AuthClientId;
const MainContent = () => {
  const [web3auth, setWeb3auth] = useState<Web3AuthNoModal | null>(null);
  const [userAccount, setUserAccount] = useState<AptosAccount | null>(null);
  const [userFullAddress, setUserFullAddress] = useState<string | null>(null);
  const [allCasts, setAllCasts] = useState<any[]>([]);
  const [userUiAddress, setUserUiAddress] = useState<string | null>(null);
  const [currentCastInput, setCurrentCastInput] = useState<string>("");
  const isNotifed = localStorage.getItem("isNotified");
  const [notify, setNotify] = useState<boolean>(
    isNotifed === "true" ? false : true
  );
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnyChangeInCast, setIsAnyChangeInCast] = useState<boolean>(false);
  useEffect(() => {
    const init = async () => {
      try {
        const chainConfig = {
          chainNamespace: CHAIN_NAMESPACES.OTHER,
          chainId: "0x1",
          rpcTarget: "https://fullnode.testnet.aptoslabs.com/v1",
          displayName: "Aptos Testnet",
          blockExplorer: "",
          ticker: "APT",
          tickerName: "Aptos",
        };
        const web3auth = new Web3AuthNoModal({
          clientId,
          chainConfig,
          web3AuthNetwork: "sapphire_devnet",
        });

        const privateKeyProvider = new CommonPrivateKeyProvider({
          config: { chainConfig },
        });

        const openloginAdapter = new OpenloginAdapter({
          privateKeyProvider,
        });

        web3auth.configureAdapter(openloginAdapter);
        web3auth.on(ADAPTER_EVENTS.CONNECTED, () => {
          if (!web3auth?.provider) return 0;
        });

        web3auth.on(ADAPTER_EVENTS.ERRORED, () => {
          // setIsSigningIn(false);
        });
        await web3auth.init();
        setWeb3auth(web3auth);
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  useEffect(() => {
    async function allCasts() {
      let all: any[] = await getAllCasts();
      setAllCasts(all.reverse());
      setIsLoading(false);
    }
    allCasts();
  }, [currentCastInput, isAnyChangeInCast]);

  const handleGetAddress = async () => {
    const privateKey = (await web3auth.provider?.request({
      method: "private_key",
    })) as string;
    const parsePrivateKey = (key: string): Uint8Array => {
      const formattedKey = key.startsWith("0x") ? key.slice(2) : key;
      if (formattedKey !== null) {
        return new Uint8Array(
          formattedKey.match(/.{1,2}/g).map((byte) => parseInt(byte, 16))
        );
      }
      throw new Error("Invalid key");
    };
    let wallet = new AptosAccount(parsePrivateKey(privateKey));
    setUserAccount(wallet);
    let addr = wallet.address().hex();
    setUserFullAddress(addr);
    whitelistAddress(addr);
    setUserUiAddress(getUiAddress(addr));
    setIsLoggedIn(true);
  };

  const login = async () => {
    if (web3auth?.status === "connected") {
      setIsLoggedIn(true);
      return;
    }
    if (!web3auth) {
      return;
    }
    await web3auth.connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      mfaLevel: "default",
      loginProvider: "google",
    });
    await handleGetAddress();
  };

  const whitelistAddress = async (address: string) => {
    await initAccount(address);
    if (!(await IsWhitelisted(address))) await AddToWhitelist(address);
  };

  const logout = async () => {
    if (!web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await web3auth.logout();
    setIsLoggedIn(false);
  };
  useEffect(() => {
    if (web3auth?.status === "connected") {
      setIsLoggedIn(true);
      handleGetAddress();
    } else {
      setIsLoggedIn(false);
    }
  }, [web3auth, isLoggedIn]);
  const loggedInView = (
    <>
      <div className="flex-container flex">
        <div className=" mx-4 my-1 p-5 cursor-pointer "> {userUiAddress}</div>
        <div className="">
          <button
            onClick={logout}
            className="w-[10.25rem] h-[3.25rem] outline-none flex justify-center items-center bg-[#808080] rounded-[1rem] text-[#000] font-[500] mr-3 mt-2"
          >
            Logout
          </button>
          <button
            onClick={logout}
            className="w-[10.25rem] h-[3.25rem] outline-none flex justify-center items-center rounded-[1rem] text-[#000] font-[500] "
          ></button>
        </div>
      </div>
    </>
  );

  const unloggedInView = (
    <>
      <button
        onClick={login}
        className="2xl:w-[10.25rem] xl:w-[10.25rem] lg:w-[10.25rem] md:w-[10.25rem] sm:w-[6.25rem] max-sm:w-[6.25rem] 2xl:h-[3.25rem] xl:h-[3.25rem] lg:h-[3.25rem] md:h-[3.25rem] sm:h-[2.25rem] max-sm:h-[2.25rem] outline-none flex justify-center items-center bg-[#33fcff] rounded-[1rem] text-[#000] font-[500] mr-3 mt-2 "
      >
        Login
      </button>
    </>
  );

  const cast = async () => {
    if (!isLoggedIn) {
      toast.error(`Please login to Post`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    if (currentCastInput.length === 0) return;
    const payload: any = {
      function:
        "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321::broadcast::cast",
      type_arguments: [],
      arguments: [currentCastInput],
      type: "entry_function_payload",
    };
    let txn = await execute(payload, userAccount);
    setCurrentCastInput("");
    setIsLoading(false);
    toast.success(
      `Successfully Posted✅. 
    Click to view on explorer!`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        onClick: () => {
          window.open(
            `https://explorer.aptoslabs.com/txn/${txn.hash}?network=testnet`,
            "_blank"
          );
        },
      }
    );
  };

  const wave = async (castIndex: number) => {
    const payload: any = {
      function:
        "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321::broadcast::wave",
      type_arguments: [],
      arguments: [castIndex],
      type: "entry_function_payload",
    };
    let txn = await execute(payload, userAccount);
    toast.success(
      `Successfully Liked❤️ 
    Click to view on explorer!`,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        onClick: () => {
          window.open(
            `https://explorer.aptoslabs.com/txn/${txn.hash}?network=testnet`,
            "_blank"
          );
        },
      }
    );
    setIsAnyChangeInCast(!isAnyChangeInCast);
  };
  const handleNotify = () => {
    setNotify(!notify);
    localStorage.setItem("isNotified", "true");
  };

  const handleCastInput = (e: any) => {
    // Update the state when the input value changes
    setCurrentCastInput(e.target.value);
  };

  return (
    <div>
      <div className=" flex ">
        <div className=" 2xl:inline-flex xl:inline-flex lg:inline-flex md:hidden sm:hidden max-sm:hidden">
          <Sidebar />
        </div>
        <div className=" text-white border-r-2 border-[#2f3336] 2xl:w-3/4 xl:w-3/4 md:w-full sm:w-full max-sm:w-full overflow-auto 2xl:ml-[25rem] xl:ml-[25rem] lg:ml-[17rem] sm:ml0 md:ml-0 max-sm:ml-0 z-[1]">
          <div className="!fixed 2xl:w-3/4 xl:w-3/4 lg:w-3/4 md:w-full sm:w-full max-sm:w-full top-0 text-[white] h-[5rem] !z-[2] py-[1rem] xxl:px-[48px] xl:px-[48px] lg:px-[48px] md:px-[48px] sm:px-[20px] xd:px-[20px] !bg-[rgba(29,30,32,0.10)] backdrop-blur-[50px] flex justify-between  ">
            <span className=" wxl:text-[2.5rem] xl:text-[2.5rem] lg:text-[2.5rem] md:text-[2.5rem] sm:text-[1.5rem] max-sm:text-[1.5rem] font-[700] flex justify-center items-center 2xl:px-0 xl:px-0 lg:px-0 md:px-4 sm:px-4 max-sm:px-4">
              Broadcast
            </span>
            <div className="grid">
              {isLoggedIn ? loggedInView : unloggedInView}
            </div>
          </div>
          <div className="border-b-2 border-[#2f3336] mt-24 h-[10rem]  ">
            <div className="flex mx-10">
              <img
                src={Mustang}
                alt="Mustang"
                className=" rounded-full w-[3.5rem] h-[3.5rem] mt-3"
              />
              <input
                className=" bg-black w-[100%] h-[5rem] outline-none border-b-2 border-[#2f3336] mx-5"
                placeholder="What is hapening?!"
                value={currentCastInput}
                onChange={handleCastInput}
              />
            </div>
            <div className=" my-4 flex 2xl:justify-center xl:justify-center lg:justify-center md:justify-start sm:justify-start max-sm:justify-start items-center">
              <button
                onClick={cast}
                disabled={isLoading}
                className="w-[5.25rem] h-[2.25rem] outline-none flex justify-center items-center bg-[#33fcff] rounded-[1rem] text-[#000] font-[500] 2xl:mx-10 xl:mx-10 lg:mx-10 md:mx-32 sm:mx-32 max-sm:mx-32 "
              >
                {isLoading ? (
                  <div
                    className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                      Loading...
                    </span>
                  </div>
                ) : (
                  "Post"
                )}
              </button>

              <ToastContainer />
            </div>
          </div>
          {notify ? (
            <div onClick={handleNotify} className=" cursor-pointer">
              <span className="m-5 bg-[#411151]  border-b-2 flex flex-col  rounded-[0.5rem] p-2 ">
                {/* <span className=" text-[#f9ff44] ">Note: </span> */}
                <span>Welcome to Kana Labs's Broadcast!</span>
                <span className="text-[#dfdfdf]">What is it?</span>
                <span>
                  Broadcast is a social app for demonstrating the workflow of
                  <a
                    href="https://docs.kanalabs.io/paymaster-service/kanalabs-paymaster"
                    className=" pl-1 text-[#33fcff] font-style: italic"
                  >
                    Kanalabs Paymaster.
                  </a>
                </span>
                <span className="text-[#dfdfdf]">How does it work?</span>
                <span>
                  Every post you make and like you give is instantaneously saved
                  to the Aptos blockchain. Kana Labs' Paymaster covers the
                  transaction fees on your behalf for these transactions. After
                  posting, you'll see a notification: {""}
                  <span className="text-[#39ff7e]">
                    Posted successfully. View on the explorer!
                  </span>{" "}
                  You can click on the notifucation to verify your transaction
                  on the Blockchain Explorer To start, please click 'Login'.
                </span>
                <span className="text-[#dfdfdf]">Warning</span>
                <span className="text-[#fff]">
                  Remember, posts on the blockchain are public and permanent.
                  Always review before hitting the post button.
                </span>
                <span className="text-[#b9c57c] flex flex-col py-1 pr-5 cursor-pointer">
                  Click anywhere to close
                </span>
              </span>
            </div>
          ) : null}
          <div>
            {allCasts.length > 0 ? (
              allCasts.map((item, index) => (
                <NewsFeed
                  key={index}
                  cast={item}
                  wave={wave}
                  isWalletConnected={isLoggedIn}
                />
              ))
            ) : (
              <p>No casts.</p>
            )}
          </div>
          {/* <NewsFeed /> */}
        </div>

        {/* <div className=" py-24 ">
          <div className=" 2xl:px-20 xl:px-20 lg:px-2 "> */}
        {/* <div className=" 2xl:w-[24rem] xl:w-[18rem] lg:w-[14rem] bg-[#202327] rounded-[1rem] flex ">
              <img src={search} alt="search" className=" pl-3 w-10 h-10 pt-4" />
              <input
                className=" bg-[#202327] 2xl:w-[22rem] xl:w-[20rem] lg:w-[12rem] rounded-[1rem] h-[3.5rem] text-white outline-none pl-4 flex"
                placeholder="Search"
              />
            </div> */}
        {/* <div className="2xl:w-[24rem] xl:w-[18rem] lg:w-[14rem] bg-[#202327] rounded-[1rem] flex h-[42rem] my-6 overflow-auto">
              <div className=" text-white font-[700] text-[1.75rem] p-4">
                What's Happening
              </div>
            </div> */}
        {/* </div>
        </div> */}
      </div>
    </div>
  );
};

export default MainContent;
