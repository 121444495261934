import { AptosClient } from "aptos";

// Feepayer address
export const feePayerAddress =
  "0xae8699a1788d18e44ca23923cc65ed7c3d1cb9c4d37d91fbab94254006009a79";
// export const paymasterBaseUrl = "http://localhost:8000";
export const paymasterBaseUrl = process.env.REACT_APP_BASE_URL as string;
export const NODE_URL = "https://fullnode.testnet.aptoslabs.com/v1";
export const aptosClient = new AptosClient(NODE_URL);
export const broadcastAddress =
  "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321";
export const castStoreResourceType =
  "0x6ac859d53df901f70b4cdd48690cad4aab52e5906f792ceac10c2efeffbf7321::broadcast::CastStore";
export const payKey = process.env.REACT_APP_API_KEY as string;
export const web3AuthClientId = process.env.REACT_APP_WEB3_AUTH as string;
