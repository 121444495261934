import { AptosAccount, BCS, HexString } from "aptos";
import axios from "axios";
import {
  aptosClient,
  feePayerAddress,
  payKey,
  paymasterBaseUrl,
} from "../Common/Constants";

const client = aptosClient;

const execute = async (payload: any, sender: AptosAccount): Promise<any> => {
  try {
    if (!sender) return { success: false, reason: "Not logged in" };
    const feePayerTxn = await client.generateFeePayerTransaction(
      sender.address().hex(),
      payload,
      feePayerAddress
    );
    // The sender signs the fee payer transaction
    const senderAuth = await client.signMultiTransaction(sender, feePayerTxn);

    // Serialize the fee payer transaction and senderAuth into a single hex string
    const serializer = new BCS.Serializer();
    feePayerTxn.serialize(serializer);
    senderAuth.serialize(serializer);

    // Collect the serialized bytes and then send the resulting hex string to your API endpoint
    const serializerdata = serializer.getBytes();
    const hexStringData = HexString.fromUint8Array(serializerdata).toString();
    const stringifiedData = JSON.stringify(hexStringData, null, 3);

    const url = `${paymasterBaseUrl}/pay-gas`;
    const res = await axios.post(url, stringifiedData, {
      headers: {
        "Content-Type": "application/json",
        "pay-key": payKey,
      },
    });
    const response = await res.data;
    return response;
  } catch (e: any) {
    console.log("catched error on execute", e);
  }
};

export default execute;
