import {
  aptosClient,
  broadcastAddress,
  castStoreResourceType,
} from "../Common/Constants";

export async function getAllCasts() {
  let castStore = (await aptosClient.getAccountResource(
    broadcastAddress,
    castStoreResourceType
  )) as any;
  return castStore.data?.casts;
}
