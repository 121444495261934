import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainContent from "./Components/MainContent";

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainContent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
